import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Inner } from 'components';
import axios, { AxiosResponse } from 'axios';
import Breakpoints from '../../themes/constants/breakpoints';
import APILinks from '../../constants/api';

// Styles
const FooterWrap = styled.footer<{ background?: string }>`
    font-size: 11px;
    font-weight: 300;
    color: ${({ theme }) => theme.color.gray};
    margin-top: auto;
    background: ${({ theme, background }) => background
        || `linear-gradient(180deg, rgba(255, 255, 255, 0), ${theme.background.footer})`};
    z-index: 10;
    p {
        text-align: justify;
        margin: 0;
    }
    a {
        color: ${({ theme }) => theme.palette.blue};
    }
    & > div {
        display: block;
        padding: 10px 30px;
        z-index: 10;
        @media only screen and (${Breakpoints.TABLET}) {
            font-size: 13px;
        }
        @media only screen and (${Breakpoints.LAPTOP}) {
            text-align: center;
            p {
                text-align: center;
            }
        }
    }
    
`;

// Interfaces
export interface IFooter {
    background?: string;
}

export interface IFooterState {
    footer: string;
    contacts: string;
}

// Component
export const Footer = (props: IFooter) => {
    const [state, setState] = useState<IFooterState>({
        footer: '',
        contacts: '',
    });

    axios.defaults.timeout = 100000;
    axios.defaults.withCredentials = true;

    useEffect(() => {
        axios.get(APILinks.FOOTER)
            .then((res: AxiosResponse<string>) => {
                setState((s) => ({
                    ...s,
                    footer: res.data,
                }));
            })
            .catch(() => {});
        axios.get(APILinks.FOOTER_UNDER)
            .then((res: AxiosResponse<string>) => {
                setState((s) => ({
                    ...s,
                    contacts: res.data,
                }));
            });
    }, []);

    const {
        background,
    } = props;

    return (
        <FooterWrap
            background={background}
        >
            <Inner>
                {/* eslint-disable react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: state.footer }} />
                <br />
                <div dangerouslySetInnerHTML={{ __html: state.contacts }} />
            </Inner>
        </FooterWrap>
    );
};

Footer.defaultProps = {
    background: '',
};
