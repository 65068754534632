export const firebaseProjectToken = 'b5901a48-3a0c-4f2d-87fe-7ec1ee8d2e1d';

// eslint-disable-next-line max-len
export const firebaseVapidKey = 'BGtcliVbDR0I3jmzRBxrTrrzvucdLkHotm3zOFJ8e63cS-8L75JtCJXpBYGrLmejd88E-3A-hWlI4v14U7wXlLI';

export const firebaseConfig = {
    apiKey: 'AIzaSyAe-viNWdQ1K7r2jY5IfOpRJGYShAb7dEA',
    authDomain: 'smartbooks-rb-life.firebaseapp.com',
    projectId: 'smartbooks-rb-life',
    storageBucket: 'smartbooks-rb-life.appspot.com',
    messagingSenderId: '92801708438',
    appId: '1:92801708438:web:93e9a8458af9a7d389edb7',
};
