import React, {
    useEffect, useRef, useState,
} from 'react';
import styled, { keyframes, useTheme } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Loader, AudioProgress } from 'components';
import { SpeedButton } from '../AudioSpeedController/SpeedButton';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { AudioStatus } from '../../redux/types';
import Breakpoints from '../../themes/constants/breakpoints';
import { setAudioCurrentTime } from '../../redux/features/audio/audioSlice';

import placeholderImg from '../../media/images/placeholder-square.png';
import { ReactComponent as PauseIcon } from '../../media/icons/pause.svg';
import { ReactComponent as PlayIcon } from '../../media/icons/play.svg';
import { ReactComponent as PrevIcon } from '../../media/icons/prev.svg';
import { ReactComponent as NextIcon } from '../../media/icons/next.svg';
import { ITheme } from '../../themes/constants/themes';
import { AudioSpeedController } from '../AudioSpeedController';

// Styles
const Wrap = styled.div`
    position: fixed;
    bottom: 0;
    left: 50%;
    z-index: 1000;
    max-width: ${({ theme }) => theme.width.wrap};
    width: 100%;
    min-width: 320px;
    transform: translateX(-50%);
    background: ${({ theme }) => theme.background.card};
    box-shadow: 0px -10px 25px rgba(0, 0, 0, 0.15);
    border-radius: 20px 20px 0 0;
    padding: 33px 40px 30px;
    overflow: hidden;
    font-size: 16px;
    line-height: 1.25;
    color: ${({ theme }) => theme.color.primary};
    @media only screen and (${Breakpoints.LAPTOP}) {
        position: unset;
        bottom: unset;
        left: unset;
        transform: unset;
        box-shadow: 0 25px 50px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        padding: 30px 50px 30px 30px;
        display: flex;
        flex-flow: row nowrap;
        min-height: 300px;
        margin-bottom: 44px;
    }
`;
const scroll = keyframes`
    0% {
        transform: translate3d(100%, 0, 0);
    }
    50% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-100%, 0, 0);
    }
`;
const Caption = styled.div<{ $isRunning?: boolean }>`
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    margin-bottom: 20px;
    flex-grow: 1;
    &:after,
    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        top: 0;
        width: 40px;
        z-index: 10;
    }
    &:before {
        content: ${({ $isRunning }) => ($isRunning ? '' : 'none')};
        left: -1px;
        height: 30px;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
    }
    &:after {
        right: -1px;
        background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0) 100%);
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        &:after,
        &:before {
            content: none;
        }
    }
`;
const CaptionItem = styled.span`
    display: block;
    position: relative;
    padding: 0;
    white-space: nowrap;
    @media only screen and (${Breakpoints.LAPTOP}) {
        white-space: unset;
        animation: none;
    }
`;
const NameWrapper = styled.div<{ $isRunning?: boolean }>`
    height: 30px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    @media only screen and (${Breakpoints.LAPTOP}) {
        height: auto;
    }
`;
const Name = styled(CaptionItem)<{ $isRunning?: boolean }>`
    position: absolute;
    min-width: 100%;
    animation-name: ${({ $isRunning }) => ($isRunning && scroll)};
    animation-duration: 60s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    margin: 0 0 4px;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
    color: ${({ theme }) => theme.color.primary};
    padding-right: ${({ $isRunning }) => ($isRunning ? '40px' : 0)};
    @media only screen and (${Breakpoints.LAPTOP}) {
        position: static;
        animation: none;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        margin: 0 0 20px;
    }
`;
const NameSecond = styled(Name)`
    transform: translate3d(-100%, 0, 0);
    animation-delay: -30s;
    display: ${({ $isRunning }) => ($isRunning ? 'block' : 'none')};
`;
const Author = styled(CaptionItem)`
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.gray};
    overflow: hidden;
    @media only screen and (${Breakpoints.LAPTOP}) {
        font-size: 20px;
        line-height: 1.2;
    }
`;
const PlayerButtons = styled.div`
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    & > *:not(:last-child){
        margin-right: 10px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        position: relative;
    }
`;
const PlayerButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    background: transparent;
    & * {
        color: ${({ theme }) => theme.color.primary};
    }
    &:disabled {
        cursor: not-allowed;
    }
`;
const PlayerButtonPlay = styled(PlayerButton)`
    background: ${({ theme }) => theme.palette.primary};
    border-radius: 100%;
    min-width: 80px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    & * {
        color: ${({ theme }) => theme.palette.white};
    }
    svg {
        height: 30px;
    }
`;
const Cover = styled.img`
    background: ${({ theme }) => theme.background.skeleton};
    display: flex;
    border-radius: 10px;
    width: 300px;
    margin-right: 60px;
    align-self: flex-start;
`;
const Player = styled.div`
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
`;

const StyledSpeedButton = styled(SpeedButton)`
    position: absolute;
    right: -17px;
    bottom: 30px;
    span {
        border: 1px solid #000;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        right: 0;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        right: 0;
        bottom: 50%;
        transform: translateY(50%);
    }
`;

const StyledAudioSpeedController = styled(AudioSpeedController)<{ open: boolean }>`
    position: absolute;
    right: 0;
    bottom: 70px;
    opacity: ${({ open }) => (open ? '1' : '0')};
    transition: ${({ theme }) => `opacity ${theme.transition.long}`};
    z-index: 10;
`;

// Interfaces
interface IAudioPrimary {
    handlePlay: () => void
    handlePause: () => void
}

// Component
export const AudioPrimary = (props: IAudioPrimary) => {
    const {
        handlePlay,
        handlePause,
    } = props;

    const dispatch = useAppDispatch();

    const isLaptop = useMediaQuery({ minWidth: '1024px' });

    const audioState = useAppSelector((store) => store.audio);

    const audioStatusState = audioState.status;
    const audioName = audioState.current.name || '';
    const audioAuthorName = audioState.current.authorName;
    const audioCoverLink = audioState.current.coverLink;
    const totalTime: number = audioState.current.time?.totalTime || 0;
    const currentTime: number = audioState.current.time?.currentTime || 0;
    const showAudioSpeedController = audioState.current.showAudioSpeedController || false;
    const isStatusPlay = audioStatusState === AudioStatus.PLAY;
    const audioLoading = audioState.loading;
    const audioCoverImg = audioCoverLink ? `/gate/f/${audioCoverLink}` : placeholderImg;

    const theme = useTheme() as ITheme;
    const captionRef = useRef<null | HTMLDivElement>(null);
    const nameRef = useRef<null | HTMLDivElement>(null);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        const captionWidth = captionRef.current?.offsetWidth;
        const nameWidth = nameRef.current?.offsetWidth;

        if (captionWidth && nameWidth && !isLaptop && nameWidth > captionWidth) {
            setIsRunning(true);
        } else {
            setIsRunning(false);
        }
    }, [captionRef.current?.offsetWidth, nameRef.current?.offsetWidth, isLaptop]);

    const handleToggleAudioStatus = () => {
        if (!isStatusPlay) {
            handlePlay();
        } else {
            handlePause();
        }
    };
    const handleBackAudioTime = () => {
        const newTime = (currentTime <= 10) ? 0 : (currentTime - 10);
        dispatch(setAudioCurrentTime(newTime));
    };
    const handleForwardAudioTime = () => {
        const newTime = ((currentTime + 10) <= totalTime) ? (currentTime + 10) : totalTime;
        dispatch(setAudioCurrentTime(newTime));
    };

    const playIcon = isStatusPlay ? <PauseIcon /> : <PlayIcon />;

    return (
        <Wrap>
            {isLaptop && <Cover src={audioCoverImg} alt={audioName} />}
            <Player>
                <Caption ref={captionRef} $isRunning={isRunning}>
                    <NameWrapper $isRunning={isRunning}>
                        <Name
                            $isRunning={isRunning}
                            ref={nameRef}
                            key="original"
                        >
                            {audioName}
                        </Name>
                        <NameSecond
                            $isRunning={isRunning}
                            key="duplicate"
                        >
                            {audioName}
                        </NameSecond>
                    </NameWrapper>
                    <Author>{audioAuthorName}</Author>
                </Caption>
                <AudioProgress
                    totalTime={totalTime}
                />
                <PlayerButtons>
                    <PlayerButton
                        type="button"
                        onClick={handleBackAudioTime}
                    >
                        <PrevIcon />
                    </PlayerButton>
                    <PlayerButtonPlay
                        type="button"
                        disabled={audioLoading}
                        aria-label="play track"
                        title="play track"
                        onClick={handleToggleAudioStatus}
                    >
                        {audioLoading
                            ? <Loader size={1} color={{ to: theme.palette.primary, from: '#fff' }} />
                            : playIcon}
                    </PlayerButtonPlay>
                    <PlayerButton
                        type="button"
                        onClick={handleForwardAudioTime}
                    >
                        <NextIcon />
                    </PlayerButton>
                    <StyledSpeedButton />
                </PlayerButtons>
                {isLaptop && <StyledAudioSpeedController open={showAudioSpeedController} />}
            </Player>
        </Wrap>
    );
};
