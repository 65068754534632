import React from 'react';
import styled from 'styled-components';
import { setShowAudioSpeedController } from '../../../redux/features/audio/audioSlice';
import { ReactComponent as SpeedometerIcon } from '../../../media/icons/speedometer.svg';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';

const Wrapper = styled.button`
    background: none;
    -webkit-tap-highlight-color: transparent;
    height: 20px;
    width: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SpeedValue = styled.span`
    font-size: 10px;
    line-height: 2;
    font-weight: 700;
    border: 1px solid #fff;
    width: 100%;
    height: 20px;
    border-radius: 5px;
`;

interface ISpeedButton {
    className?: string
}

export const SpeedButton = ({ className }: ISpeedButton) => {
    const dispatch = useAppDispatch();
    const audioState = useAppSelector((store) => store.audio);
    const showAudioSpeedController: boolean = audioState.current.showAudioSpeedController || false;
    const speed = audioState.current.time.speed || 1;

    return (
        <Wrapper
            type="button"
            className={className}
            onClick={() => dispatch(setShowAudioSpeedController(!showAudioSpeedController))}
        >
            {speed === 1 ? <SpeedometerIcon /> : <SpeedValue>{speed}</SpeedValue>}
        </Wrapper>
    );
};

SpeedButton.defaultProps = {
    className: '',
};
