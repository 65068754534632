import { firebaseTokenFetch } from 'helpers/firebase';
import Cookies from 'js-cookie';
import { useVerifyQuery } from '../generated/graphql';
import CookieName from '../constants/cookies';

function useFirebase() {
    useVerifyQuery({
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            const msisdn = data?.user?.msisdn;
            if (msisdn) {
                const firebaseToken = Cookies.get(CookieName.FIREBASE_TOKEN);
                const firebaseMSISDN = Cookies.get(CookieName.FIREBASE_MSISDN) || '';
                const isOldMSISDN = `${msisdn}` === firebaseMSISDN;

                if (firebaseToken && msisdn && !isOldMSISDN) {
                    firebaseTokenFetch({
                        token: firebaseToken,
                        oldToken: firebaseToken,
                        msisdn,
                    });
                } else {
                    Cookies.set(CookieName.FIREBASE_MSISDN, `${msisdn}`);
                }
            }
        },
    });
}

export default useFirebase;
