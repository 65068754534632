import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './redux';
import {
    setAudioCurrentTime,
    setAudioLoading,
    setAudioStatus,
    setAudioTotalTime,
} from '../redux/features/audio/audioSlice';
import { AudioStatus } from '../redux/types';
import {
    LibraryType,
    useAddLibraryTypeMutation,
    useRemoveLibraryTypeMutation,
    useLibraryContentByIdLazyQuery,
    useSetListeningPositionMutation,
} from '../generated/graphql';

// Component
const useAudio = (): [HTMLAudioElement, () => void, () => void] => {
    const dispatch = useAppDispatch();
    const [addLibraryType] = useAddLibraryTypeMutation();
    const [removeLibraryType] = useRemoveLibraryTypeMutation();

    const [setListeningPosition] = useSetListeningPositionMutation();

    const [audio] = useState(new Audio());

    const audioState = useAppSelector((store) => store.audio);
    const audioStatus = audioState.status;
    const audioCurrentTime = audioState.current.time.currentTime;
    const isStatusPlay = audioStatus === AudioStatus.PLAY;
    const isStatusPause = audioStatus === AudioStatus.PAUSE;
    const audioId = audioState.current.id;
    const audioSpeed = audioState.current.time.speed;

    const { fileSrc } = audioState.current;
    const src: string = fileSrc ? `/gate/p/${fileSrc}` : '';

    const [getLibraryContentById, { data }] = useLibraryContentByIdLazyQuery();

    useEffect(() => {
        if (audioId) {
            getLibraryContentById({
                variables: {
                    id: audioId,
                },
            })
                .catch(() => {});
        }
    }, [audioId]);

    useEffect(() => {
        if (src) {
            audio.src = src;
            audio.load();
            audio.preload = 'auto';
            audio.currentTime = audioState.current.time.currentTime ?? 0;
            audio.playbackRate = audioState.current.time.speed || 1;
            dispatch(setAudioCurrentTime(0));
            dispatch(setAudioLoading(true));
        }
    }, [src, audioId]);

    useEffect(() => {
        if (audioSpeed && audioSpeed !== audio.playbackRate) {
            audio.playbackRate = audioSpeed;
        }
    }, [audioSpeed]);

    const libraryContentInfo = data?.point?.service.content;
    const isReadNow = libraryContentInfo?.readNow;
    const isReaded = libraryContentInfo?.readed;

    const handleSetStatus = (status: AudioStatus) => dispatch(setAudioStatus(status));
    const handlePlay = () => {
        audio.play()
            .then(() => {
                if (!isStatusPlay) {
                    dispatch(setAudioStatus(AudioStatus.PLAY));
                }
            })
            .catch(() => {});
    };
    const handlePause = () => {
        audio.pause();
        if (!isStatusPause) {
            dispatch(setAudioStatus(AudioStatus.PAUSE));
        }
    };

    useEffect(() => {
        const time: number = audioCurrentTime ?? 0;

        const isMoreThenZero = time !== 0; // Текущее время не равно нулю
        const isMultipleOfTen = (time % 10) === 0; // Текущее время кратно 10 сек
        const diffMoreThenTen = Math.abs(time - audio.currentTime) > 10; // Ползунок сдвинут больше чем на 10 сек
        const needSavePosition = audioId && isMoreThenZero && (isMultipleOfTen || diffMoreThenTen);

        if (needSavePosition) {
            setListeningPosition({
                variables: {
                    id: audioId,
                    position: time > 5 ? time - 5 : time,
                },
            }).catch(() => {});
        }

        if (Math.floor(audio.currentTime) !== time) {
            audio.currentTime = time;
        }
    }, [audioId, audioCurrentTime]);

    useEffect(() => {
        const canplayListener = () => {
            dispatch(setAudioLoading(false));
        };
        const endedListener = () => {
            audio.currentTime = 0;
            if (!isReaded && audioId) {
                addLibraryType({
                    variables: {
                        id: audioId,
                        type: LibraryType.Readed,
                    },
                })
                    .catch(() => {});
            }
            if (isReadNow && audioId) {
                removeLibraryType({
                    variables: {
                        id: audioId,
                        type: LibraryType.ReadNow,
                    },
                })
                    .catch(() => {});
            }
            handleSetStatus(AudioStatus.PAUSE);
        };
        const durationchangeListener = () => {
            dispatch(setAudioTotalTime(audio?.duration || 0));
        };
        const timeupdateListener = () => {
            dispatch(setAudioCurrentTime(Math.floor(audio.currentTime)));
        };
        const playingListener = () => {
            handleSetStatus(AudioStatus.PLAY);
            if (isReaded && audioId) {
                removeLibraryType({
                    variables: {
                        id: audioId,
                        type: LibraryType.Readed,
                    },
                })
                    .catch(() => {});
            }
            if (!isReadNow && audioId) {
                addLibraryType({
                    variables: {
                        id: audioId,
                        type: LibraryType.ReadNow,
                    },
                })
                    .catch(() => {});
            }
        };
        const pauseListener = () => {
            handleSetStatus(AudioStatus.PAUSE);
        };

        audio.addEventListener('canplay', canplayListener);
        audio.addEventListener('playing', playingListener);
        audio.addEventListener('pause', pauseListener);
        audio.addEventListener('ended', endedListener);
        audio.addEventListener('durationchange', durationchangeListener);
        audio.addEventListener('timeupdate', timeupdateListener);

        return () => {
            audio.removeEventListener('canplay', canplayListener);
            audio.removeEventListener('playing', playingListener);
            audio.removeEventListener('pause', pauseListener);
            audio.removeEventListener('ended', endedListener);
            audio.removeEventListener('durationchange', durationchangeListener);
            audio.removeEventListener('timeupdate', timeupdateListener);
        };
    }, [audio, audioId, isReaded, isReadNow]);

    return [audio, handlePlay, handlePause];
};

export default useAudio;
