import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AudioGlobal } from 'components';
import { useAppSelector } from '../../hooks/redux';
import { AudioStatus } from '../../redux/types';

// Interfaces
interface IAudio {
    handlePlay: () => void;
    handlePause: () => void;
}

// Component
export const Audio = (props: IAudio) => {
    const {
        handlePlay,
        handlePause,
    } = props;

    const history = useHistory();

    const isListenPath = `${history?.location?.pathname}`.slice(-6) === 'listen';
    const isReadPath = `${history?.location?.pathname}`.slice(-4) === 'read';

    const audioState = useAppSelector((store) => store.audio);
    const isStatusPlay = audioState.status === AudioStatus.PLAY;
    const isStatusStop = audioState.status === AudioStatus.STOP;

    useEffect(() => {
        if (isStatusPlay && isReadPath) {
            handlePause();
        }
    }, [isReadPath]);

    const showAudio = (!isStatusStop && !isReadPath) && !isListenPath;

    if (showAudio) {
        return (
            <AudioGlobal
                handlePlay={handlePlay}
                handlePause={handlePause}
            />
        );
    }
    return null;
};
