import React from 'react';
import ReactDOM from 'react-dom';
import { initializeApp } from 'firebase/app';
import { Root } from 'components';
import reportWebVitals from 'reportWebVitals';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import { firebaseRegistration } from 'helpers/firebase';
import { firebaseConfig } from 'constants/firebase';

initializeApp(firebaseConfig);

// Render App
ReactDOM.render(<Root />, document.getElementById('root'));

// Dev tools util
reportWebVitals();

// Service worker
serviceWorkerRegistration.register({
    onSuccess: firebaseRegistration,
    onUpdate: firebaseRegistration,
});
