import CompilationAliases from 'constants/compilations';
import {
    ComponentType,
    lazy,
    LazyExoticComponent,
} from 'react';
import { RouteConfig } from 'react-router-config';
import { InfoPageTypes } from 'pages/InfoPage';
import RoutePath from './constants/routes';

// LazyPages
const HomePage = lazy(() => import('pages/HomePage/HomePage'));
const SearchPage = lazy(() => import('pages/SearchPage'));
const BooksPage = lazy(() => import('pages/BooksPage/BooksPage'));
const BooksDetailReadPage = lazy(() => import('pages/BooksDetailReadPage'));
const BooksDetailListenPage = lazy(() => import('pages/BooksDetailListenPage'));
const MyBooksPage = lazy(() => import('pages/MyBooksPage'));
const BooksDetailPage = lazy(() => import('pages/BooksDetailPage/BooksDetailPage'));
const InfoPage = lazy(() => import('pages/InfoPage'));
const Page404 = lazy(() => import('pages/Page404'));

export interface CustomRouteConfig extends RouteConfig {
    title?: string;
    alias?: CompilationAliases;
    type?: InfoPageTypes;
    component: LazyExoticComponent<ComponentType<any>>;
}

// RoutesArr
const routes: CustomRouteConfig[] = [
    {
        path: RoutePath.BOOKS_DETAIL_READ_PATH,
        component: BooksDetailReadPage,
    },
    {
        path: RoutePath.BOOKS_DETAIL_LISTEN_PATH,
        component: BooksDetailListenPage,
    },
    {
        path: RoutePath.BOOKS_DETAIL_PATH,
        component: BooksDetailPage,
    },
    {
        path: RoutePath.BOOKS_PATH,
        component: BooksPage,
        title: 'Все книги',
        alias: CompilationAliases.ALL,
    },
    {
        path: RoutePath.POPULAR_PATH,
        component: BooksPage,
        title: 'Популярное в мире',
        alias: CompilationAliases.POPULAR,
    },
    {
        path: RoutePath.NEW_PATH,
        component: BooksPage,
        title: 'Новинки',
        alias: CompilationAliases.NEW,
    },
    {
        path: RoutePath.RECOMMEND_PATH,
        component: BooksPage,
        title: 'Рекомендуем',
        alias: CompilationAliases.RECOMMEND,
    },
    {
        path: RoutePath.FOR_PARENTS_PATH,
        component: BooksPage,
        title: 'Для родителей',
        alias: CompilationAliases.FOR_PARENTS,
    },
    {
        path: RoutePath.CATEGORIES_WITH_ALIAS_PATH,
        component: BooksPage,
    },
    {
        path: RoutePath.CATEGORIES_PATH,
        component: BooksPage,
    },
    {
        path: RoutePath.MY_BOOKS_PATH,
        component: MyBooksPage,
    },
    {
        path: RoutePath.SEARCH_PATH,
        component: SearchPage,
    },
    {
        path: RoutePath.OFFER_PATH,
        component: InfoPage,
        type: 'offer',
    },
    {
        path: RoutePath.INFO_PATH,
        component: InfoPage,
        type: 'info',
    },
    {
        path: RoutePath.ROOT_PATH,
        exact: true,
        component: HomePage,
    },
    {
        component: Page404,
    },
];

export default routes;
