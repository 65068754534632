import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import useAddToHomeScreen from 'hooks/useAddToHomeScreen';
import { checkIsIOS, checkIsInStandaloneMode } from 'helpers/platform';
import { ReactComponent as XIcon } from 'media/icons/x.svg';
import { ReactComponent as IOSMenuIcon } from 'media/icons/ios-menu.svg';
import Breakpoints from 'themes/constants/breakpoints';
import { useMediaQuery } from 'react-responsive';

const buzzOut = keyframes`
    3% {
      transform: translateX(3px) rotate(2deg);}
    6% {
      transform: translateX(-3px) rotate(-2deg);}
    9% {
      transform: translateX(3px) rotate(2deg);}
    12% {
      transform: translateX(-3px) rotate(-2deg);}
    15% {
      transform: translateX(2px) rotate(1deg);}
    18% {
      transform: translateX(-2px) rotate(-1deg);}
    21% {
      transform: translateX(2px) rotate(1deg);}
    24% {
      transform: translateX(-2px) rotate(-1deg);}
    27% {
      transform: translateX(1px) rotate(0);}
    30% {
      transform: translateX(-1px) rotate(0);}
`;
const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px;
    z-index: 1000;
    @media only screen and (${Breakpoints.LAPTOP}) {
        &:last-child {
            position: fixed;
            right: 0;
            bottom: 0;
            transform: unset;
        }
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        position: fixed;
        right: 0;
        bottom: 0;
        transform: unset;
    }
`;
const WrapBase = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 460px;
    background: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.black};
    box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    pointer-events: auto;
    animation: ${buzzOut} 2.7s linear infinite;
    @media only screen and (${Breakpoints.LAPTOP}) {
        width: 380px;
    }
`;

const WrapInstallApp = styled(WrapBase)`
    bottom: 10px;
    max-width: calc(100vw - 20px);
    padding: 6px 40px 6px 6px;
    height: 62px;
`;
const WrapIOS = styled(WrapBase)`
    bottom: 15px;
    max-width: calc(100vw - 30px);
    padding: 15px 40px 15px 15px;
    height: 75px;
`;
const ImageBase = styled.img.attrs({ alt: '' })`
    flex-shrink: 0;
    border-radius: 5px;
    background: ${({ theme }) => theme.background.skeleton};
`;
const ImageInstallApp = styled(ImageBase)`
    width: 50px;
    height: 50px;
    margin-right: 6px;
`;
const ImageIOS = styled(ImageBase)`
    width: 45px;
    height: 45px;
    margin-right: 15px;
`;

const TextBase = styled.div`
    flex-grow: 1;
    font-weight: 400;
    font-size: 10px;
    user-select: none;
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 12px;
        line-height: 1.2;
    }
`;
const TextInstallApp = styled(TextBase)`
    line-height: 1.1;
    margin-right: 10px;
`;
const TextIOS = styled(TextBase)`
    line-height: 1.2;
    & svg {
        width: 13px;
        height: 13px;
        margin: 0 2px -2px;
    }
`;
const InstallAppButton = styled.button.attrs({ type: 'button' })`
    flex-shrink: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.16;
    background: ${({ theme }) => theme.palette.green};
    color: ${({ theme }) => theme.palette.white};
    height: 30px;
    padding: 8px 10px;
    margin-left: auto;
    border-radius: 5px;
`;
const CloseButtonBase = styled.button.attrs({ type: 'button' })`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.palette.black};
    background: ${({ theme }) => theme.background.skeleton};
    border-radius: 100%;
    & > svg {
        width: 18px;
        height: 18px;
    }
`;
const CloseButtonInstallApp = styled(CloseButtonBase)`
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
`;
const CloseButtonIOS = styled(CloseButtonBase)`
    top: 10px;
    right: 10px;
`;

function AddToHomeScreen() {
    const isLaptop = useMediaQuery({ query: `(${Breakpoints.LAPTOP})` });

    const [show, setShow] = useState<boolean>(false);
    const [canShow, setPromptCanShow] = useState<boolean>(false);

    const { prompt, promptToInstall } = useAddToHomeScreen();

    const canInstallApp = !!prompt;
    const isIOS = checkIsIOS();
    const isInStandaloneMode = checkIsInStandaloneMode();
    const canShowModal = canShow && (canInstallApp || (isIOS && !isInStandaloneMode));

    const handleClose = () => setShow(false);
    const handleInstall = () => {
        promptToInstall();
        handleClose();
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = document.documentElement.scrollTop;
            const availHeight = window.screen?.availHeight ?? 0;

            if (scrollPosition >= availHeight) setPromptCanShow(true);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (canShowModal) setShow(true);
    }, [canShowModal]);

    const homePageName = () => {
        if (isLaptop) return 'рабочий стол';
        return 'домашний экран';
    };

    const serviceName = 'SMARTBOOKS';
    const installAppText = `Добавить ярлык «${serviceName}» на ${homePageName()}?`;

    const imgSrc = `${process.env.PUBLIC_URL}/img/icon-192x192.png`;

    const installAppModalEl = (
        <Wrapper>
            <WrapInstallApp onClick={handleInstall}>
                <ImageInstallApp src={imgSrc} />
                <TextInstallApp>{installAppText}</TextInstallApp>
                <InstallAppButton>Добавить</InstallAppButton>
                <CloseButtonInstallApp
                    onClick={(ev) => {
                        ev.stopPropagation();
                        handleClose();
                    }}
                >
                    <XIcon />
                </CloseButtonInstallApp>
            </WrapInstallApp>
        </Wrapper>
    );
    const IOSModalEl = (
        <Wrapper>
            <WrapIOS>
                <ImageIOS src={imgSrc} />
                <TextIOS>
                    <div>{installAppText}</div>
                    <div>
                        Нажмите на
                        <IOSMenuIcon />
                        и выберите «На экран «Домой»
                    </div>
                </TextIOS>
                <CloseButtonIOS onClick={handleClose}>
                    <XIcon />
                </CloseButtonIOS>
            </WrapIOS>
        </Wrapper>
    );

    if (show) {
        if (canInstallApp) return installAppModalEl;
        if (isIOS) return IOSModalEl;
        return null;
    }

    return null;
}

export default AddToHomeScreen;
