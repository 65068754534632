import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { Statistic } from '@b3w/react-statistic';
import { PrivacyPolicy } from '@b3w/pp-portal';
import '@b3w/pp-portal/dist/portal-bundle.css';
import { Preloader, Audio } from 'components';
import routes from 'routes';
import useAudio from 'hooks/useAudio';
import Banners from 'components/Banners/Banners';
import { AudioSpeedControllerPortal } from 'components/AudioSpeedControllerPortal';
import axios, { AxiosResponse } from 'axios';
import APILinks from '../../constants/api';

// Component
const RouterProvider = () => {
    const [, handlePlay, handlePause] = useAudio();
    const [text, setText] = useState<string | null>(null);

    useEffect(() => {
        axios.get(APILinks.PRIVACY_FOOTER)
            .then((res: AxiosResponse<string>) => {
                if (res.data && res.status === 200) {
                    const regExp = /%.*%/;
                    const match = res.data.match(regExp);
                    const substr = match ? match[0] : '';
                    const linkName = substr ? substr.slice(1, substr.length - 1) : '';
                    const linkHtml = `<a href="http://lk.funzone.by/privacypolicy/?service=rb-mts-smartbooks">
                        ${linkName}</a>`;
                    const html = res.data.replace(substr, linkHtml);
                    setText(html);
                }
            })
            .catch(() => {});
    }, []);

    return (
        <Suspense
            fallback={<Preloader />}
        >
            <BrowserRouter>
                {text ? <PrivacyPolicy dangerousText={text} adoption /> : null}
                <Statistic path="/referer/statistic" from timeoutMinutes={1} />
                {renderRoutes(routes, {
                    handlePlay,
                    handlePause,
                })}
                <Route
                    render={() => (
                        <Audio
                            handlePlay={handlePlay}
                            handlePause={handlePause}
                        />
                    )}
                />
                <Route
                    render={() => (
                        <AudioSpeedControllerPortal />
                    )}
                />
                <Route
                    render={() => (
                        <Banners />
                    )}
                />
            </BrowserRouter>
        </Suspense>
    );
};

export default RouterProvider;
