import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Authors, Controls, Loader } from 'components';
import truncate from 'helpers/truncate';
import Breakpoints from 'themes/constants/breakpoints';
import { PreviewTypes } from 'redux/types';
import {
    LogicOperator,
    ShortContentFragment, usePointQuery, useShortContentsLazyQuery,
} from 'generated/graphql';

import placeholder from '../../media/images/placeholder.png';
import getMetaParams from '../../helpers/getMetaParams';
import CompilationAliases from '../../constants/compilations';

// Styles
const Wrapper = styled.div`
    background: ${({ theme }) => theme.palette.white};
    padding-top: 40px;
    padding-bottom: 40px;
    ${({ theme }) => theme.paddings.primary}
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    @media only screen and (${Breakpoints.TABLET}) {
        ${({ theme }) => theme.paddings.tablet}
    }
`;
const Tile = styled.div`
    text-decoration: none;
    width: 210px;
    height: 310px;
    background-position: 50% center;
    flex-shrink: 0;
    background-size: cover;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    user-select: none;
    z-index: 1;
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
    margin: 0 20px 0 0;
    cursor: pointer;
    *,
    &:hover,
    &:active,
    &:visited {
        color: ${({ theme }) => theme.color.white};
    }
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50.52%, rgba(0, 0, 0, 0.6) 100%);
        z-index: -1;
    }
    &:after {
        pointer-events: none;
        position: absolute;
        content: '';
        display: block;
        left: 9px;
        top: 0;
        bottom: 0;
        width: 2px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.09) 0%, rgba(0, 0, 0, 0.21) 100%);
    }
    @media only screen and (${Breakpoints.TABLET}) {
        width: 450px;
        height: 664px;
        padding: 53px 43px;
        margin-right: 50px;
        &:after {
            left: 19px;
            width: 4px;
        }
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        width: 280px;
        height: 412px;
        margin-right: 30px;
        &:after {
            left: 12px;
            width: 3px;
        }
    }
`;
const Title = styled.h3`
    font-family: ${({ theme }) => theme.fontFamily.serif};
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    word-break: break-word;
    cursor: pointer;
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 34px;
        line-height: 43px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        font-size: 24px;
        line-height: 30px;
    }
`;
const StyledAuthors = styled(Authors)`
    margin: auto 0 0;
    @media only screen and (${Breakpoints.LAPTOP}) {
        margin-top: 15px;
    }
    p {
        font-size: 14px;
        line-height: 16px;
        margin: 0 0 4px;
        font-weight: 400;
        @media only screen and (${Breakpoints.TABLET}) {
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 6px;
        }
        @media only screen and (${Breakpoints.LAPTOP}) {
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 4px;
        }
    }
`;
const Info = styled.div`
    display: flex;
    flex-direction: column;
    @media only screen and (${Breakpoints.LAPTOP}) {
        padding: 30px 0;
        display: flex;
        flex-direction: column;
    }
`;
const CustomControls = styled(Controls)`
    flex: 1 1;
    margin: 0 -10px 0 0;
    flex-direction: column;
    @media only screen and (${Breakpoints.TABLET}) {
        margin-right: -30px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        flex-direction: row;
        margin: auto 0 0;
        flex-grow: unset;
    }
`;

// Interface
export interface ITopBook {
    className?: string;
    isLaptop: boolean;
    index: number;
}

// Component
export const TopBook = ({ className, isLaptop, index }: ITopBook) => {
    const history = useHistory();
    const [book, setBook] = useState<ShortContentFragment | null>(null);

    const { data, loading } = usePointQuery();
    const [getItem, { data: contentData, loading: contentLoading }] = useShortContentsLazyQuery();

    const totalContent = data?.point?.service.categories
        ?.filter((category) => category.alias === CompilationAliases.ALL)[0]?.totalContent;

    const offsetRandom = useMemo(() => {
        if (data && totalContent) {
            return Math.floor(Math.random() * totalContent);
        }
        return 0;
    }, [index, totalContent]);

    const fetchBookCallback = () => {
        getItem({
            variables: {
                limit: 1,
                offset: offsetRandom,
                filter: {
                    typeName: 'book',
                },
                paramFilters: {
                    negative: false,
                    logicOperator: LogicOperator.And,
                    params: [
                        {
                            paramName: 'ebook_exist',
                            value: ['true'],
                        },
                        {
                            paramName: 'abook_exist',
                            value: ['true'],
                        },
                    ],
                },
            },
        })
            .then((res) => {
                if (res.data?.point?.service.contents && res.data?.point?.service.contents[0]) {
                    setBook(res.data?.point?.service.contents[0]);
                }
            })
            .catch(() => {});
    };

    useEffect(() => {
        fetchBookCallback();
    }, [offsetRandom]);

    if (loading || contentLoading) return <Loader />;

    const creators = getMetaParams(book?.metaContentParams, 'creator') || [];

    const previewLink = book?.previews?.filter(({ type }) => type === PreviewTypes.MAIN)[0]?.link;
    const cover = previewLink ? `/gate/f/${previewLink}` : placeholder;

    const renderInfo = () => (
        <>
            <Title onClick={() => history.push(`/books/${book?.id}`)}>{truncate(book?.name || '', 55)}</Title>
            {creators && <StyledAuthors creators={creators} maxToShow={3} />}
        </>
    );

    return (
        <Wrapper className={className}>
            {contentData && (
                <>
                    <Tile
                        onClick={() => history.push(`/books/${book?.id}`)}
                        style={{ backgroundImage: `url(${cover})` }}
                    >
                        {!isLaptop && renderInfo()}
                    </Tile>
                    <Info>
                        {isLaptop && renderInfo()}
                        {book && <CustomControls book={book} fetchBookCallback={fetchBookCallback} />}
                    </Info>
                </>
            )}
        </Wrapper>
    );
};

TopBook.defaultProps = {
    className: '',
};
