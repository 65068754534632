enum APILinks {
    PRIVACY_FOOTER = '/text/common-text-service/privacyfooter/1',
    OFERTA = '/text/smartbook-by-life/oferta/1',
    FOOTER = '/text/smartbook-by-life/footer/1',
    FOOTER_UNDER = '/text/smartbook-by-life/footer-under/1',
    HEADER_SUPPORT = '/text/smartbook-by-life/header-support/1',
    FIREBASE_TOKEN = '/firebase/token',
}

export default APILinks;
