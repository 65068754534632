import React, {
    ChangeEvent, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getTimerFromSeconds } from '../../helpers/time';
import { AudioStatus } from '../../redux/types';
import { setAudioCurrentTime } from '../../redux/features/audio/audioSlice';

// Styles
const Wrap = styled.div`
    display: flex;
    flex-flow: column nowrap;
    margin: 0 0 5px;
`;
const InputWrap = styled.div<{ disabled?: boolean }>`
    position: relative;
    background: #c4c4c4;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    z-index: 10;
    margin: 5px 0;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
    & > div:last-child {
        background: ${({ disabled }) => disabled && 'unset'}
    }
`;
const Progress = styled.div`
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    height: 10px;
    background: ${({ theme }) => theme.palette.primary};
    border-radius: 5px;
    z-index: -10;
`;
const Input = styled.input`
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: inherit;
    background: transparent;
    -webkit-appearance: none;
    &:focus {
      outline: none;
    }
    &::-webkit-slider-runnable-track {
        background: transparent;
        border: 0;
        border-radius: 5px;
        width: 100%;
        height: 10px;
        cursor: pointer;
    }
    &::-webkit-slider-thumb {
        margin-top: -5px;
        width: 20px;
        height: 20px;
        background: ${({ theme, disabled }) => (disabled ? '#ccc' : theme.palette.primary)};
        border: 0;
        border-radius: 50px;
        cursor: pointer;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
        -webkit-appearance: none;
    }
    @supports (-ms-ime-align:auto) {
        margin: 0;
    }
`;
const Counter = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    & span {
        font-size: 12px;
        line-height: 20px;
        color: ${({ theme }) => theme.palette.gray};
    }
`;

// Interfaces
interface IAudioProgress {
    totalTime: number
    disabled?: boolean
}

// Component
export const AudioProgress = (props: IAudioProgress) => {
    const {
        totalTime,
        disabled,
    } = props;

    const dispatch = useAppDispatch();

    const audioState = useAppSelector((store) => store.audio);
    const currentTime: number = audioState.current.time?.currentTime || 0;
    const isStatusStop = audioState.status === AudioStatus.STOP;

    const [value, setValue] = useState<number>(currentTime);

    const progressRef = useRef<HTMLDivElement>(null);

    const progress: number = ((100 / ((totalTime + 10) - 1)) * ((value + 10) - 1)) || 0;

    useEffect(() => {
        setValue(currentTime);
    }, [currentTime]);

    useEffect(() => {
        if (progressRef.current) {
            if (totalTime !== 0) {
                progressRef.current.style.width = `${progress}%`;
            }
            if (progress <= 0) {
                progressRef.current.style.width = '0%';
            }
        }
    }, [progress]);

    useEffect(() => {
        if (progressRef.current && isStatusStop) {
            progressRef.current.style.width = '0%';
        }
    }, [isStatusStop]);

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const newValue = +ev.target.value || 0;
        // setValue(newValue);
        dispatch(setAudioCurrentTime(newValue));
    };

    return (
        <Wrap>
            <InputWrap
                disabled={disabled}
            >
                <Input
                    type="range"
                    value={value}
                    min={1}
                    disabled={disabled}
                    max={totalTime}
                    onChange={handleChange}
                />
                <Progress
                    ref={progressRef}
                />
            </InputWrap>
            <Counter>
                <span>{getTimerFromSeconds(value)}</span>
                <span>{getTimerFromSeconds(totalTime)}</span>
            </Counter>
        </Wrap>
    );
};

AudioProgress.defaultProps = {
    disabled: false,
};
